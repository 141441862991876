import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false

}

export default function Footer(props) {
    return (
        <React.Fragment>
            {/* {JSON.stringify(props.content_data)} */}
            <Slider {...settings}>
                {props.content_data.map(item => (
                    <div className="item" key={item._id}>
                        <div className="inner">
                            <div className="first-fold">
                                <h3> {item.title} </h3>
                            </div>
                            <div className="over">
                                <h3> {item.title} </h3>
                                <p>{item.short_description}</p>
                                <Link activeClassName="active" to={`${props.mainLink}/${props.type == 'team' ? item.title : item.slug}/#${props.hashLink}`}>Read more</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </React.Fragment>
    )
}
