import React, { Component } from 'react';
import Layout from "../components/layout";
import { Link } from "gatsby";
import home_our_practice_mobile from "../images/home_our_practice_mobile.jpg";
import Slider from '../components/slider';
import Nav from '../components/nav';


class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      isBlogLoaded: false,
      blogContent: [],
      isAwardLoaded: false,
      awardContent: [],
      pageContent: [],
      ispageLoader: false,

      isTeamLoaded: false,
      teamTtems: [],

    }
  }

  async componentDidMount() {
    this.getAllPracticeArea();
    this.getPageDetailsById();
    this.getAllAwards();
    this.getAllTeams();
  }

  componentWillReceiveProps(nextProps) {
    this.getAllPracticeArea();
    this.getPageDetailsById();
    this.getAllAwards();
    this.getAllTeams();
  }


  async getAllTeams() {
    fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/team")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredDates = result.data.sort((a, b) => a.order_id.localeCompare(b.order_id))
          this.setState({
            isTeamLoaded: true,
            teamTtems: filteredDates
          });
        },
        (error) => {
          this.setState({
            isTeamLoaded: true,
            error
          });
        }
      )
  }

  getAllAwards() {
    fetch("https://tatva.com/backendapi/api/v1/cms/by-type/award")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredDates = result.data
          console.log("filteredDates uniqueChars", filteredDates)

          this.setState({
            isAwardLoaded: true,
            awardContent: filteredDates
          });
        },
        (error) => {
          this.setState({
            isAwardLoaded: true,
            error
          });
        }
      )
  }

  getAllBlog() {
    fetch("https://tatva.com/backendapi/api/v1/cms/by-type/blog")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredDates = result.data.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
          console.log("filteredDates", filteredDates)
          this.setState({
            isBlogLoaded: true,
            blogContent: filteredDates
          });
        },
        (error) => {
          this.setState({
            isBlogLoaded: true,
            error
          });
        }
      )
  }

  getAllPracticeArea() {
    fetch("https://tatva.com/backendapi/api/v1/cms/by-type/practice")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredDates = result.data.sort((a, b) => new Date(b.order_id) - new Date(a.order_id))
          this.setState({
            isLoaded: true,
            items: filteredDates
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  getPageDetailsById() {
    fetch("https://tatva.com/backendapi/api/v1/cms/60b5b8349a07e16f89ec496f")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            ispageLoader: true,
            pageContent: result,
          });
        },
        (error) => {
          this.setState({
            ispageLoader: true,
            error
          });
        }
      )
  }

  render() {

    const { isLoaded, items, awardContent, isAwardLoaded, pageContent, ispageLoader, isTeamLoaded, teamTtems } = this.state;

    return (
      <Layout>

        {ispageLoader ?
          <Nav
            active="home"
            pageTitle={pageContent.data?.title}
            title={pageContent.data?.document_title}
            description={pageContent.data?.meta_description}
            keywords={pageContent.data?.meta_tag}
          ></Nav>
          : ''
        }

        <div className="main-container" id="home_page_content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-sm-12">
                <div className="home-intro text-center">
                  <h1> Tatva Legal, Hyderabad is the largest full-service law firm in the States of Telangana & Andhra Pradesh </h1>
                </div>
              </div>
            </div>
          </div>

          <div id="home_eagle">
            <div className="container">
              <div className="row justify-content-center">
                {/* awardContent,isAwardLoaded */}
                <div className="col-md-12 col-sm-12">
                  <div className="home-intro-awards">
                    <div className="home-intro-awards1">
                      <ul>

                        {isAwardLoaded ?
                          <React.Fragment>
                            {awardContent.map(item => (
                              <li key={item._id}>
                                {item.short_description}
                              </li>
                            ))}
                          </React.Fragment>
                          : ''}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-12">
                  <div className="home-intro2 text-center">
                    <h1> Awarded the Best Regional Law Firm in India several years in a row </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="home_map">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12 col-sm-12">
                  <div className="home-intro3 text-center">
                    <h1> <Link activeClassName="active" to={"/about/"}>Our Forté</Link> </h1>
                    <h3>An unparalled combination of legal expertise and deep-rooted knowledge of the market
                      practices, nuances and sensitivities within the region is our forté. This has helped us
                      represent national and international clients (including several Fortune 100 companies)
                      in some of the largest and most complex transactions in the country.</h3>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div id="home_about">
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-md-12 col-sm-12">
                  <div className="our-pratice-home text-left">
                    <h1> <Link activeClassName="active" to={"/about/"}>About Us</Link> </h1>
                    <h3>Tatva Legal Hyderabad has consistently been ranked as India’s best regional law firm since the year 2013. We are the largest full-service law firm in the states of Telangana and Andhra Pradesh. </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="home_about_img">
            <img src={home_our_practice_mobile} title="" alt="" />
          </div>




          <div className="our-pratice-item-home-wrapper">
            <div className="container-fluid">
              <div className="row justify-content-end">
                <div className="col-md-12 col-sm-12">
                  <div className="our-pratice-home text-center">
                    <h1> <Link activeClassName="active" to={"/our-practice-areas/mergers-and-acquisitions-private-equity-and-joint-ventures/"}>Our Practice Areas</Link> </h1>
                    <h3>We provide legal services in all areas of law including:</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="container mobile" id="our-pratice-item-home">
              {isLoaded ?
                <React.Fragment>
                  <Slider
                    mainLink='our-practice-areas'
                    hashLink='middle_content'
                    type='practiceAreas'
                    content_data={items}></Slider>
                </React.Fragment>
                : ''}
            </div>

            <div className="container-fluid desktop" id="our-pratice-item-home">
              <div className="row justify-content-start">
                {isLoaded ?
                  <React.Fragment>
                    {items.map(item => (
                      <div className="col-md-4 col-sm-12 item" key={item._id}>
                        <div className="inner">
                          <div className="first-fold">
                            <h3> {item.title} </h3>
                          </div>
                          <div className="over">
                            <h3> <Link activeClassName="active" to={"/our-practice-areas/" + item.slug + "/#middle_content"}> {item.title} </Link> </h3>
                            <p>{item.short_description}</p>
                            <Link activeClassName="active" className="read_more2" to={"/our-practice-areas/" + item.slug + "/#middle_content"}>Read more</Link>
                          </div>
                        </div>
                      </div>

                    ))}
                  </React.Fragment>
                  : ''}
              </div>
            </div>

          </div>

          <div className="our-pratice-item-home-wrapper">
            <div className="container-fluid">
              <div className="row justify-content-end">
                <div className="col-md-12 col-sm-12">
                  <div className="our-pratice-home text-center">
                    <h1> <Link activeClassName="active" to={"/team-by-category/Founder & Managing Partner/"}>Team</Link> </h1>
                    <h3>Our team of experienced and highly qualified lawyers are assisted by a large pool of associates and senior associates.</h3>
                  </div>
                </div>
              </div>
            </div>


            <div className="container mobile" id="our-pratice-item-home">
              {isTeamLoaded ?
                <React.Fragment>
                  <Slider
                    mainLink='team-by-category'
                    hashLink='team-page'
                    type='team'
                    content_data={teamTtems}>
                      
                  </Slider>
                </React.Fragment>
                : ''}
            </div>

            <div className="container-fluid desktop" id="our-pratice-item-home">
              <div className="row justify-content-start">
                {isTeamLoaded ?
                  <React.Fragment>
                    {teamTtems.map(item => (
                      <div className="col-md-4 col-sm-12 item" key={item._id}>
                        <div className="inner">
                          <div className="first-fold">
                            <h3> {item.title} </h3>
                          </div>
                          <div className="over">
                            {/* team-by-category/Senior%20Partners/#team-page */}
                            <h3> <Link activeClassName="active" to={"/team-by-category/" + item.title + "/#team-page"}> {item.title} </Link> </h3>
                            <p>{item.short_description}</p>
                            <Link activeClassName="active" className="read_more2" to={"/team-by-category/" + item.title + "/#team-page"}>Read more</Link>
                          </div>
                        </div>
                      </div>

                    ))}
                  </React.Fragment>
                  : ''}
              </div>
            </div>
          </div>


          {/* <div className="home-blog-list">
            <div className="container-fluid">
              <div className="row justify-content-end">
                <div className="col-md-12 col-sm-12">
                  <div className="our-home-blog text-left">
                    <h1> <Link activeClassName="active" to={"/blog/"}>Our Blog</Link> </h1>
                    <h3>Our blog provides legal and commercial information, analysis and updates of national and regional relevance.</h3>
                    <div className="home-blog-list-wrapper">
                      {isBlogLoaded ?
                        <React.Fragment>                          
                          {blogContent.slice(0, 4).map(item => (
                            <React.Fragment>
                              <div className="home-blog-list-item" key={item._id}>                                
                                <BlogItem
                                  category={item.category}
                                  category_show="true"
                                  title={item.title}
                                  author={item.created_by}
                                  date={item.publish_date}
                                  short_content={item.short_description}
                                  description={item.description}
                                  slug={item.slug}
                                >
                                </BlogItem>
                              </div>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        </div>



      </Layout>
    )
  }
}

export default IndexPage;
